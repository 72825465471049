<template lang="pug">
.about.view
    section.intro
        .section-content-wrapper
            h1 О сервисе
            h3 Сервис представляет из себя базу данных обмена и хранения подписок (opt-in) и отписок (opt-out) абонентов от массовых (рекламных) рассылок SMS-сообщений.
            p За время существования сервиса накопилось множество вопросов от абонентов и ответы на них достаточно полно описывают особенности сервиса.
    section.qa
        .section-content-wrapper
            .grid
                .grid-column
                    h4
                        b Какова основная цель сервиса?
                    p Исполнение Федерального закона "О связи" в части регулирования рассылок по сети электросвязи, обладающая признаками автоматизированной отправки и осуществляемая одновременно или за короткий промежуток времени большому числу абонентов.
                .grid-column
                    h4
                        b Какой механизм работы сервиса?
                    p С одной стороны к сервису подключены абоненты, а с другой - сеть партнеров, в числе которых крупный ретейл и рекламные агентства, которые заинтересованы в лояльности своих клиентов и более эффективных рассылках.
                    p Прежде чем делать рассылку партнер проверяет желение абонента получать такие сообщения.
                .grid-column
                    h4
                        b Почему сервис бесплатный?
                    p Считаем, что сервисы такого рода могут развиваться только на безвоздмезной основе, т.к. он лежит в поле правого регулирования взаимоотношений и какой либо порог не даст ему двигаться вперед. Мы одержимы идеей сделать рынок SMS рассылок более цивилизованным в нашей стране.
                .grid-column
                    h4
                        b Сервис дает стопроцентную гарантию фильтрации сообщений?
                    p К сожалению, нет. Партнеров у нас немало, но пока не все. По нашим подсчетам сервис может влиять примерно на 40% рассылок в РФ.
                .grid-column
                    h4
                        b В чем ценность сервиса для рекламных агентств?
                    p Предоставив право абоненту отписаться достигается три цели:
                        ol
                            li Повышение лояльности абонента к компании. Раз дают право отписаться значит уважают, снижают степень раздражения.
                            li Экономия средств. За счет снижения абонентской базы, благодаря отписавшимся.
                            li Снижение риска жалоб в контролирующие органы о нарушении Федерального закона "О связи". Последствия - штрафы и судебные издержки.
                .grid-column
                    h4
                        b Ведь если предоставить право отписаться, то все абоненты отпишутся от всех рассылок?
                    p Имея уже немалый опыт сбора согласий можем с уверенностью сказать, что процент отписок небольшой - редко доходит до 5, а обычно 1-2.
                .grid-column
                    h4
                        b Бытует мнение, что отписавшись на сервисе opt-in ты подписываешься на спам рассылку?
                    p Конечно же нет. Цель сервиса защитить от спам рассылок, а не увеличить их.
</template>

<script>
import {mapActions} from "vuex";
import {
    GET_CAPTCHA,
    SEND_FEEDBACK_FORM,
} from "@/store/types"

const default_feedback_form = () => {
    return {
        company_name: '',
        name: '',
        contacts: '',
        message: '',
        captcha: '',
    }
}

export default {
    data(){
        return {
            feedback_form: default_feedback_form(),
            captcha: {
                img: null,
            },
            feedback_form_rules: {
                company_name: [
                    {required: true, message: 'Укажите название компании', trigger: 'blur'},
                ],
                name: [
                    {required: true, message: 'Укажите ваше имя', trigger: 'blur'},
                ],
                contacts: [
                    {required: true, message: 'Укажите контактную информацию', trigger: 'blur'},
                ],
                captcha: [
                    {required: true, message: 'Символы', trigger: 'blur'},
                    {min: 4, max: 4, message: '4 символа', trigger: 'blur'},
                ],
            },
        }
    },
    created(){
        this.refreshCaptcha();
    },
    methods: {
        ...mapActions([
            GET_CAPTCHA,
            SEND_FEEDBACK_FORM,
        ]),
        refreshCaptcha() {
            return this.GET_CAPTCHA().then((captcha) => {
                this.captcha = captcha;
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message);
                    }))

                    return Promise.reject(error);
                }
            });
        },
        sendFeedback(){
            this.$refs['feedback_form'].validate((valid) => {
                if (valid) {
                    this.SEND_FEEDBACK_FORM({...this.feedback_form, ...{key: this.captcha.key}}).then(()=>{
                        this.$root.pushAppMessages('Сообщение отправлено', 'success');
                    }).then(()=>{
                        this.refreshCaptcha();
                        this.feedback_form = default_feedback_form();
                    }).catch((error) => {
                        this.refreshCaptcha();

                        if (error.response.data.errors) {
                            this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                                return this.$t(message);
                            }))

                            return Promise.reject(error);
                        }
                    });
                }}
            );
        },
    }
}
</script>

<style lang="scss" scoped>
.about {
    background: $color--background;
    color: $color--black;

    p{
        margin-bottom: 1.5em;

        &:last-child{
            margin-bottom: 0;
        }
    }
    .grid{
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }
    ol{
        list-style-type: decimal;
        list-style-position: inside;
    }
    .intro, .invitation{
        background: $color--gray-light;
        color: #fff;
    }
    .captcha-row{
        display: flex;
    }
    .captcha-code{
        width: 5em;
    }

    @media (max-width: $max-width--tablets) {
        .grid {
            grid-template-columns: 1fr;
        }
    }
}
</style>
